function captureImage() {
    let cameraDirection = 'front';
    let mediaRequest = {
        allowedMimeTypes: ['image/jpeg'],
        allowedSources: ['camera'],
        cameraDirection: cameraDirection
    };

    let IsSpot = localStorage.getItem('isSpot');
    let IsDevice =localStorage.getItem('ISDevice');

    if (IsSpot == 'SPOT') {
        microapps.requestMedia(mediaRequest).then(
            (response) => {
                const response_data = {
                    Imagebytes: response.bytes,
                    Imagetype: response.mimeType
                };
                var event = new CustomEvent('Uploadsuccess', {
                    detail: response_data,
                    bubbles: true,
                    cancelable: true
                });
                window.dispatchEvent(event);
            },
            function failedCallBack(response) {
                console.log(JSON.stringify(response));
                if (response.message.includes('update the GPay app') && (oldApp == null || oldApp != 'Y')) {
                    let result = { version: 'O' };
                    console.log(JSON.stringify(result));
                } else console.log(JSON.stringify(response));
            }
        );
    } 
}

function OpenFileExplorer() {
    // mediaapirequest = {
    //   "allowedMimeTypes": ["image/jpeg","application/pdf"],
    //   "allowedSources": ["files"]
    // };
    let cameraDirection = 'back';
    let mediaRequest = {
        allowedMimeTypes: ["image/jpeg","application/pdf"],
        allowedSources: ['camera', 'files'],
        cameraDirection: cameraDirection
    };
    let IsSpot = localStorage.getItem('isSpot');
    let IsDevice =localStorage.getItem('ISDevice');

    if (IsSpot == 'SPOT') {
        microapps.requestMedia(mediaRequest).then(
            (response) => {
                const response_data = {
                    Imagebytes: response.bytes,
                    Imagetype: response.mimeType
                };
                var event = new CustomEvent('Uploadsuccess', {
                    detail: response_data,
                    bubbles: true,
                    cancelable: true
                });
                window.dispatchEvent(event);
            },
            function failedCallBack(response) {
                console.log(JSON.stringify(response));
                if (response.message.includes('update the GPay app') && (oldApp == null || oldApp != 'Y')) {
                    let result = { version: 'O' };
                    console.log(JSON.stringify(result));
                } else console.log(JSON.stringify(response));
            }
        );
    } else if (IsDevice == 'android') return Android.fetchImage(JSON.stringify(mediaRequest));
    else return window.webkit.messageHandlers.fetchImage.postMessage(mediaRequest);
}

function openUrl(Url){
  let IsSpot = localStorage.getItem('isSpot');
  let IsDevice =localStorage.getItem('ISDevice');
    if (IsSpot == 'SPOT') microapps.openUrl({ url: Url });
    else if (IsDevice == 'android') return Android.closePWA(JSON.stringify(data));
    else return window.webkit.messageHandlers.closePWA.postMessage(data);
}

function jsVideoCallBack() {
    let mediaRequest = {
        allowedMimeTypes: ['video/*'],
        allowedSources: ['camera'],
        cameraDirection: 'front',
        maxVideoSeconds: 5
    };
    let IsDevice =localStorage.getItem('ISDevice');
    if (IsDevice == 'android') return Android.fetchIPV(JSON.stringify(mediaRequest));
    else return window.webkit.messageHandlers.fetchIPV.postMessage(mediaRequest);
}
function captureImagePWA()
{
    let cameraDirection = 'front';
    let mediaRequest = {
        allowedMimeTypes: ['image/jpeg'],
        allowedSources: ['camera'],
        cameraDirection: cameraDirection
    };
    let IsDevice =localStorage.getItem('ISDevice');
    if (IsDevice == 'android') return Android.fetchImage(JSON.stringify(mediaRequest));
    else return window.webkit.messageHandlers.fetchImage.postMessage(mediaRequest);
}

function fetchImageResponseAndroid(response) {
    try {
    const response_data = {
            Imagebytes:response.split(",")[1],
            Imagetype: response.split(",")[0].split(";")[0].split(":")[1],
        };
        var event = new CustomEvent('Uploadsuccess', {
            detail: response_data,
            bubbles: true,
            cancelable: true
        });
        window.dispatchEvent(event);
     } catch (e) {
     }
}
// image response from native app ios
 function fetchImageResponseiOS(response) {
    try {
        const response_data = {
            Imagebytes: response.bytes,
            Imagetype: response.mimeType
        };
        var event = new CustomEvent('Uploadsuccess', {
            detail: response_data,
            bubbles: true,
            cancelable: true
        });
        window.dispatchEvent(event);
    
     } catch (e) {
     }
}

function base64ToByteArray(base64String) {
    const byteCharacters = atob(base64String);
    var byteArray = new Array(byteCharacters.length);
    for (var i = 0; i < len; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteArray);
  }
  
  function uploadMedia(blob) {
   let formData = new FormData();
   formData.append("form_field_name", blob, "my_file_name.jpg");
   let request = new XMLHttpRequest();
   request.open("POST", "http://myspot.com/submitform.php");
   request.send(formData);
  }
