const { isAndroid } = require('@fingerprintjs/fingerprintjs');
const { response } = require('express');
// importScripts('https://s3-eu-west-1.amazonaws.com/static.wizrocket.com/js/sw_webpush.js');

async function getGpayMobileNumber(isPermissionAllow) {
    try {
        let payload = {};
        //if (skipPrompt == true)
        payload = { skipPrompt: isPermissionAllow, testVersion: 'v2' };
        let getUserPhoneNumber = microapps.getPhoneNumber(payload).then(
            function successCallBack(response) {
                // console.log(response);
                // var response =
                //     'eyJhbGciOiJSUzI1NiIsImtpZCI6ImU2MDJkYmYzM2RhYTUwYzE5NDAxOWZlNDM2YmJhMDc2NjQwZDQwOWIiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJodHRwczovL21pY3JvYXBwcy5nb29nbGUuY29tLzg1MDg3MjE5MTk5MDMwOTI5NjQiLCJpc3MiOiJodHRwczovL21pY3JvYXBwcy5nb29nbGUuY29tIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjp0cnVlLCJwaG9uZV9udW1iZXIiOiIrOTEgOTAyODUxMTM1MyIsImV4cCI6MTY0NDU3MTY5NiwiaWF0IjoxNjQ0NTY4MDk2fQ.IJMg43MGXoGgnxYImaTqOP8mWkxHi0Gspz6OWffBDMLFGCuMYDWHSzP09a1prmfUto9gUvT3JhZl0F0cuO0FXJHWJrTiE5n_LO6Bow6BUlRsIbgg8TEu1tDp_lvQXZtm_9K5hIlNjGNcgfb8Q0G_EivXnf_0jECJMBOxtxSzxyPn1uu5KCx2_XgAMezaHGfwMGHXNSvpmWhUExxBHADC_4ly446nFvFMSoUYHMMVwAarqH4dyhNXvsb0pdokU9X3gjQZ0wecd1cvDBtbT1_mls6qji2oCR6kPE5nyh9vIhm59CzVhOsLaqAvBPFUkWn8W5yjGue5a5adDCeWG55EZg';

                const _responseApiMobile = JSON.parse(atob(response.split('.')[1]));
                //let phone_number ="9029920152";
                let phone_number = _responseApiMobile.phone_number;
                var UserMobile = phone_number.replace(/\D/g, '').slice(-10);
                if (UserMobile != '') {
                    if (localStorage.getItem('userMobile') != UserMobile) {
                        //localStorage.clear();
                    }
                    localStorage.setItem('userMobile', UserMobile);
                    localStorage.setItem('isSpot', 'SPOT');
                    const response_data = {
                        UserMobile: UserMobile,
                        IsMobileConsent: 'Y',
                        VerifyType: 1
                    };

                    var event = new CustomEvent('success', {
                        detail: response_data,
                        bubbles: true,
                        cancelable: true
                    });
                    window.dispatchEvent(event);
                } else {
                    //localStorage.clear();
                }
            },
            function failedcallback(_responseApi) {
                if (_responseApi) {
                    if (_responseApi.message.includes('API_PERMISSION_DENIED') || 'API_PERMISSION_USER_CANCELLED') {
                        const response_data = {
                            IsMobileConsent: 'N',
                            VerifyType: 1
                        };
                        var event = new CustomEvent('failpermission', {
                            detail: response_data,
                            bubbles: true,
                            cancelable: true
                        });
                        window.dispatchEvent(event);
                    }
                }
            }
        );
    } catch (e) {
        console.log(e);
    }
}
async function getGPayEmailId(isPermissionAllow) {
    try {
        let payload = {};
        payload = { skipPrompt: isPermissionAllow, testVersion: 'v2' };
        microapps.getIdentity(payload).then(
            (response) => {
                console.log(response);
                const decoded = JSON.parse(atob(response.split('.')[1]));
                const _ResponseEmail = JSON.parse(atob(response.split('.')[1]));

                let UserEmail = _ResponseEmail.email.trim();
                if (UserEmail != '') {
                    localStorage.setItem('isSpot', 'SPOT');
                    const response_data = {
                        UserEmail: UserEmail,
                        IsEmailConsent: 'Y',
                        VerifyType: 2
                    };
                    var event = new CustomEvent('success', {
                        detail: response_data,
                        bubbles: true,
                        cancelable: true
                    });
                    window.dispatchEvent(event);
                }
            },
            function failedcallback(_responseApi) {
                if (_responseApi) {
                    if (_responseApi.message.includes('API_PERMISSION_DENIED') || 'API_PERMISSION_USER_CANCELLED') {
                        const response_data = {
                            IsEmailConsent: 'N',
                            VerifyType: 2
                        };
                        var event = new CustomEvent('failpermission', {
                            detail: response_data,
                            bubbles: true,
                            cancelable: true
                        });
                        window.dispatchEvent(event);
                    }
                }
            }
        );
    } catch (e) {
        console.log(e);
    }
}

function getlatlongdetails() {
    microapps.getCurrentLocation().then(
        (response) => {
            console.log('getCurrentLocation response: ', response);
            localStorage.setItem('IsLocationAllow', 'Y');
            var event = new CustomEvent('fetchlatlong', {
                detail: response,
                bubbles: true,
                cancelable: true
            });
            window.dispatchEvent(event);
        },
        function failedCallBack(response) {
            if (response) {
                if (response.message.includes('API_PERMISSION_DENIED')) {
                    localStorage.setItem('IsLocationAllow', 'N');
                    getlatlongdetails();
                }
            }
        }
    );
}

function shareScript(ScripCode, Exch, ExchType, Name) {
    microapps
        .requestSharing({
            title: 'Track this stock',
            text: 'Get live prices and trade at just Rs. 20/order',
            url:
                'https://microapps.google.com/8508721919903092964?Scrip=' +
                ScripCode +
                '&exch=' +
                Exch +
                '&exchtype=' +
                ExchType +
                '&symbol=' +
                Name +
                '&fullname=' +
                Name
        })
        .then((response) => {
            console.log('requestSharing success:- ' + response);
        });
}

function showToast(message) {
    // androidApp = name defined in WebView client on JsInterface
    Android.makeToast(message, true);
}

function fetchImageResponseAndroid(response) {
    try {
        console.log(response);
       const response_data = {
           Imagebytes: response.bytes,
           Imagetype: response.mimeType
       };
       var event = new CustomEvent('Uploadsuccess', {
           detail: response_data,
           bubbles: true,
           cancelable: true
       });
       window.dispatchEvent(event);
   
    } catch (e) {
    }
   }

function invokeAppCallBack(clientDetails) {
    return Android.invokeAppCallBack(clientDetails);
}

function JsCallback(jwt, sessionid, clientcode, clientstatus) {
    let IsDevice =localStorage.getItem('ISDevice');
    const data = {
        JWT: jwt,
        SessionId: sessionid,
        ClientCode: clientcode,
        ClientStatus: clientstatus
    };
    if (IsDevice == 'android') return Android.invokeAppCallBack(JSON.stringify(data));
    else return window.webkit.messageHandlers.invokeAppCallBack.postMessage(data);
}

function getFetchImage(){
    Android.fetchImage("PARTNERSHIPS");
}

function sendJsCallBack(data) {
    let IsDevice =localStorage.getItem('ISDevice');
    if (IsDevice == 'android') return Android.closePWA(JSON.stringify(data));
    else return window.webkit.messageHandlers.closePWA.postMessage(data);
}


