const ts = require("typescript");

const microAppGoogleActionUrl = "https://microapps.google.com/8508721919903092964";
const FivePimageUrl = "https://spot.5paisa.com/content/img/logo.png"

function ZohoScript() {
    console.log("zoho script ---------> ");
    window.ZohoHCAsapSettings = {
        ticketsSettings: {
            preFillFields: {
                customFields: {
                    "Spot ASAP": {
                        defaultValue: 'true',
                        isHidden: true
                    }
                }
            }
        }
    };
    window.ZohoHCAsap = window.ZohoHCAsap || function (a, b) { ZohoHCAsap[a] = b; };
    (function () { var d = document; var s = d.createElement("script"); s.type = "text/javascript"; s.defer = true; s.src = "https://desk.zoho.in/portal/api/web/inapp/27465000406945907?orgId=60005106983"; d.getElementsByTagName("head")[0].appendChild(s); })();
}

//Call Zoho Script
function CallZohoScript() {
    window.ZohoHCAsapReady = function () {
        ZohoHCAsap.Action("open");
    }
    console.log("script called");
}

function InitiatePayment(amount, transactionId,isRazorpayEnable,vpa) {
    try {
        var reqdata = {
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
                {
                    type: 'UPI',
                    parameters: {
                        payeeVpa: isRazorpayEnable == 'Y' ? vpa : '5paisa@hdfcbank',
                        payeeName: '5Paisa Capital Ltd',
                        mcc: '6211',
                        transactionId: '',
                        transactionReferenceId: transactionId
                    },
                    tokenizationSpecification: { type: 'DIRECT', parameters: {} }
                }
            ],
            merchantInfo: { merchantId: 'BCR2DN6T5WQ3JRDN' },
            transactionInfo: {
                currencyCode: 'INR',
                countryCode: 'IN',
                totalPriceStatus: 'FINAL',
                totalPrice: amount.toString()
            }
        };
        
        
        microapps.requestPayment(reqdata).then(response => {
            console.log('requestPayment response: ', response);
            GetTransaction(response);
          }).catch(error => {
            console.error('An error occurred: ', error);
          });
    } catch (e) {
        console.log(e);
    }
}

function GetTransaction(response) {
    //{ "apiVersion": 2, "apiVersionMinor": 0, "paymentMethodData": { "type": "UPI", "tokenizationData": { "type": "DIRECT", "token": "{\"protocolVersion\":\"ECV2\",\"signature\":\"\",\"signedMessage\":\"{\\\"paymentMethod\\\":\\\"UPI\\\",\\\"paymentMethodDetails\\\":{\\\"payeeVpa\\\":\\\"5paisa@hdfcbank\\\",\\\"transactionReferenceId\\\":\\\"158452914272952129171\\\",\\\"transactionId\\\":\\\"SBIf7d51407f9814858b24783f46a0976f4\\\",\\\"status\\\":\\\"SUCCESS\\\",\\\"transactionInfo\\\":{\\\"currencyCode\\\":\\\"INR\\\",\\\"totalPriceStatus\\\":\\\"FINAL\\\",\\\"totalPrice\\\":\\\"2.00\\\",\\\"transactionNote\\\":\\\"\\\"}},\\\"messageExpiration\\\":\\\"0\\\"}\"}" } } };
    try {
        console.log("api response", response);
        let paymentToken = JSON.parse(response.paymentMethodData.tokenizationData.token);
        if (paymentToken) {
            let signedMessage = JSON.parse(paymentToken.signedMessage);
            let paymentDetail = signedMessage.paymentMethodDetails;
            console.log("json response",signedMessage);
            console.log("new json", paymentDetail);
           
                if (paymentDetail.status == 'SUCCESS') {
                    title = 'Your payment has been Added successfully';
                    //StatusLabel = response.data.Message;
                    StatusType = 'COMPLETED';
                } else {
                    title = 'Your payment transaction has failed';
                    StatusLabel = response.data.Message;
                    StatusType = 'CANCELLED';
                }
                var responseData ={
                    resTitle :title,
                    resStatusType :StatusType
                }
                var event = new CustomEvent('PaymentResponse', {
                    detail: responseData,
                    bubbles: true,
                    cancelable: true
                });
                window.dispatchEvent(event);
        }
    } catch (e) {
        console.log(e);
    }
}
function CreateOrderForAddFund(response) {
        try{
            window['AddFundComponent'].prototype.paymentSuccessFrequency();
        }catch(e){
                console.error("------------------------"+e)
        }


  let paymentStatus =
        '?Status=' +
        response.status +
        '&txnRefId=' +
        response.transactionReferenceId +
        '&TxnId=' +
        response.transactionId +
        '&Amount=' +
        response.transactionInfo.totalPrice;
    var obj = {
        ClientCode: localStorage.getItem('ClientCode'),
        Action: 'ADD',
        Status: response.status,
        TransactionReferenceId: response.transactionReferenceId,
        TransactionId: response.transactionId,
        TokenNumber: '',
        Message: '',
        Amount: parseFloat(response.transactionInfo.totalPrice).toFixed(2)
    };
    let orderRequest,
        title,
        itemTitle,
        StatusLabel,
        StatusType,
        imageUrl,
        btnActionLabel,
        btnActionURL,
        Amount = null;
    

    //StatusType, title, StatusType based on response.Status
    if (response.status == 'SUCCESS') {
        title = 'Your payment has been Added successfully';
        //StatusLabel = response.data.Message;
        StatusType = 'COMPLETED';
    } else {
        title = 'Your payment transaction has failed';
        StatusLabel = response.data.Message;
        StatusType = 'CANCELLED';
    }
    var responseData ={
        resTitle :title,
        resStatusType :StatusType
    }
    var event = new CustomEvent('PaymentResponse', {
        detail: responseData,
        bubbles: true,
        cancelable: true
    });
    window.dispatchEvent(event);

}

