function detectOTP2() {
    const signal= new AbortController();
    setTimeout(()=>{
      signal.abort();
      return false;
    },60000)
    if ('OTPCredential' in window) {
        // window.addEventListener('DOMContentLoaded',detectOTPCore,true);
        document.addEventListener('click',detectOTPCore,true);
    }   
}
function isInteger(value) {
  return /^\d+$/.test(value);
}
function detectOTP() {
  detectOTPCore();
}
function detectOTPCore() {
  try{
    if(navigator.credentials){
      try {
        navigator.credentials.get(
          {
              otp:{
                  transport:['sms']
              },
              signal:signal.signal
          }
        ).then((content)=>{
          console.log(content);
          var otpVal=content.code;
          if(otpVal.length==6){
            if(isInteger(otpVal)){
              localStorage.setItem("otp",otpVal);
            }
            else{
              return;
            }
          }
          else{
            return;
          }
        }).catch((error)=>{
          return; 
        });
      }
      catch(e){
        return;
      }
    }
  }
  catch(e){
    return;
  }
}
